import React from "react"
import { Link } from "gatsby"
import * as styles from "./header.module.scss"

const HeaderLink = props => (
  // this can be done, because of return a single line without logic, just the rendered html
  <Link className={styles.link} to={props.to}>
    {props.text}
  </Link>
)

const HomeButton = props => (
  // this can be done, because of return without logic, just the rendered html
  <Link to="/">
    <div className={styles.button}>{props.text}</div>
  </Link>
)

const SocialButton = props => {
  let style = ""
  let url = ""

  if (props.site === "twitter") {
    style = styles.buttonTwitter
    url = "https://twitter.com/" + props.username
  } else if (props.site === "linkedin") {
    style = styles.buttonLinkedin
    url = "https://linkedin.com/in/" + props.username
  } else if (props.site === "github") {
    style = styles.buttonGithub
    url = "https://github.com/" + props.username
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <div className={style}>{props.children}&nbsp;</div>
    </a>
  )
}

const Header = () => {
  return (
    <header className={styles.container}>
      <div className={styles.row}>
        <HomeButton text="MY APP" />
        <SocialButton site="github" username="test" />
        <SocialButton site="linkedin" username="test" />
        <SocialButton site="twitter" username="test" />
      </div>

      <div className={styles.row}>
        <HeaderLink to="/" text="HOME" />
        <HeaderLink to="/about" text="ABOUT ME" />
        <HeaderLink to="/list" text="LIST" />
      </div>
    </header>
  )
}

export default Header;
