import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

// must be in this syntax, otherwise throws an error
import * as styles from "./404.module.scss"

const Status404 = () => {
  return (
    <Layout>
      <div className={styles.content}>
        <h1 className={styles.header}>Page not found</h1>
        <p className={styles.errorMessage}>
          The page your are looking for does not exists.
        </p>
        <Link to="/">Home</Link>
      </div>
    </Layout>
  )
}

export default Status404
